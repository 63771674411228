import App from '../components/App/App'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Welcome from '../components/Common/Welcome'
import Button from '@mui/material/Button'
import { hasAccess, clientWindow, variantToBool, consoleDev, getSetUrlParams, baseContext, buildQueryString } from '../src/helpers/helper' // eslint-disable-line no-unused-vars
import styles from '../src/styles/index.module.css'
import { navigationMenu } from '../src/dictionaries/dictionaries'
// import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'
import useRIUser from '../src/hooks/riUser'
// import { useEffect, useRef } from 'react'

const thisFile = 'pages/index ' // eslint-disable-line no-unused-vars

const location = clientWindow?.location
const Index = () => {
  const { user, accessRules } = useRIUser()

  // // Setup Mongo Charts SDK Instance as a ref
  // const mongoCharts = useRef(
  //   new ChartsEmbedSDK({
  //     baseUrl: 'https://charts.mongodb.com/charts-caseopp-asjpw'
  //   })
  // )
  // // Load the MongoDb Chart
  // useEffect(() => {
  //   if (user) {
  //     const chartInstance = mongoCharts.current.createChart({
  //       chartId: '445b0307-f351-41cd-b96b-2a43c0ce3748',
  //       autoRefresh: false,
  //       maxDataAge: 60 * 10,
  //       width: '90%',
  //       height: '600px',
  //       showAttribution: false
  //     })
  //     chartInstance.render(document.getElementById('signedCasesChartDiv'))
  //       .catch(() => window.alert('Chart signedCasesChartDiv failed to initialize'))
  //   }
  // }, [user])

  const HeaderBoardComponent = (
    <Typography className='qa-logged-in-welcome' variant='h5' style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
      Welcome, {user?.name}
    </Typography>
  )

  if (user) {
    const { urlParams } = getSetUrlParams()
    const newQueryString = buildQueryString(urlParams)
    // Shared grid props
    const gridProps = {
      direction: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      style: { margin: '1em', padding: '1em' }
    }
    // Shared button props
    const buttonProps = ({ page, icon }) => {
      return {
        className: styles.button,
        size: 'large',
        color: 'secondary',
        variant: 'contained',
        'aria-label': 'opportunitiesGrid', // Directly passed so must be straight css and NOT ariaLabel
        // eslint-disable-next-line no-return-assign
        onClick: () => location.href = '/' + page + newQueryString,
        startIcon: icon
      }
    }
    // All the unique information needed for the render
    const opportunities = navigationMenu.find(menuItem => menuItem.page === 'opportunities')
    const cases = navigationMenu.find(menuItem => menuItem.page === 'cases')
    const intakeDashboard = navigationMenu.find(menuItem => menuItem.page === 'intakeDashboard')
    const caseManagerDashboard = navigationMenu.find(menuItem => menuItem.page === 'caseManagerDashboard')
    const supervisorDashboard = navigationMenu.find(menuItem => menuItem.page === 'supervisorDashboard')
    const campaigns = navigationMenu.find(menuItem => menuItem.page === 'campaigns')
    const settings = navigationMenu.find(menuItem => menuItem.page === 'settings')

    const gridRows = {
      grids: {
        title: 'Grids',
        hasAccessOpps: hasAccess('page:' + opportunities.page, accessRules, user),
        oppsButton: {
          page: opportunities.route || opportunities.page,
          icon: opportunities.icon,
          innerHtml: opportunities.label
        },
        hasAccessCases: hasAccess('page:' + cases.page, accessRules, user),
        casesButton: {
          page: cases.route || cases.page,
          icon: cases.icon,
          innerHtml: cases.label
        },
        buttons: []
      },
      dashboards: {
        title: 'Dashboards',
        hasAccessIntake: hasAccess('page:' + intakeDashboard.page, accessRules, user),
        intakeDashboardButton: {
          page: intakeDashboard.route || intakeDashboard.page,
          icon: intakeDashboard.icon,
          innerHtml: intakeDashboard.label
        },
        hasAccessCaseManager: hasAccess('page:' + caseManagerDashboard.page, accessRules, user),
        caseManagerDashboardButton: {
          page: caseManagerDashboard.route || caseManagerDashboard.page,
          icon: caseManagerDashboard.icon,
          innerHtml: caseManagerDashboard.label
        },
        hasAccessSupervisor: hasAccess('page:' + supervisorDashboard.page, accessRules, user),
        supervisorDashboardButton: {
          page: supervisorDashboard.route || supervisorDashboard.page,
          icon: supervisorDashboard.icon,
          innerHtml: supervisorDashboard.label
        },
        buttons: []
      },
      dashboards2: { // This isn't being displayed by default but is left here so it can be enabled to see how a 4 button row looks
        title: 'Dashboards2',
        hasAccessIntake: hasAccess('page:' + intakeDashboard.page, accessRules, user),
        intakeDashboardButton: {
          page: intakeDashboard.route || intakeDashboard.page,
          icon: intakeDashboard.icon,
          innerHtml: intakeDashboard.label
        },
        hasAccessCaseManager: hasAccess('page:' + caseManagerDashboard.page, accessRules, user),
        caseManagerDashboardButton: {
          page: caseManagerDashboard.route || caseManagerDashboard.page,
          icon: caseManagerDashboard.icon,
          innerHtml: caseManagerDashboard.label
        },
        hasAccessCaseManager2: hasAccess('page:' + caseManagerDashboard.page, accessRules, user),
        caseManagerDashboardButton2: {
          page: caseManagerDashboard.route || caseManagerDashboard.page,
          icon: caseManagerDashboard.icon,
          innerHtml: caseManagerDashboard.label + '2'
        },
        hasAccessSupervisor: hasAccess('page:' + supervisorDashboard.page, accessRules, user),
        supervisorDashboardButton: {
          page: supervisorDashboard.route || supervisorDashboard.page,
          icon: supervisorDashboard.icon,
          innerHtml: supervisorDashboard.label
        },
        buttons: []
      },
      toolsAndSettings: {
        title: 'Tools and Settings',
        hasAccessCampaigns: hasAccess('page:' + campaigns.page, accessRules, user),
        campaignsButton: {
          page: campaigns.route || campaigns.page,
          icon: campaigns.icon,
          innerHtml: campaigns.label
        },
        hasAccessSettings: hasAccess('page:' + settings.page, accessRules, user),
        settingsButton: {
          page: settings.route || settings.page,
          icon: settings.icon,
          innerHtml: settings.label
        },
        buttons: []
      }
    }

    /*
        Now build the actual button components.  This sets our access and unique button props inline.
      */
    // grids
    if (gridRows.grids.hasAccessOpps) {
      gridRows.grids.hasAccess = true
      gridRows.grids.buttons.push(
        <Button
          {...buttonProps(gridRows.grids.oppsButton)}
        >
          {gridRows.grids.oppsButton.innerHtml}
        </Button>
      )
    }
    if (gridRows.grids.hasAccessCases) {
      gridRows.grids.hasAccess = true
      gridRows.grids.buttons.push(
        <Button
          {...buttonProps(gridRows.grids.casesButton)}
        >
          {gridRows.grids.casesButton.innerHtml}
        </Button>
      )
    }
    // dashboards
    if (gridRows.dashboards.hasAccessIntake) {
      gridRows.dashboards.hasAccess = true
      gridRows.dashboards.buttons.push(
        <Button
          {...buttonProps(gridRows.dashboards.intakeDashboardButton)}
        >
          {gridRows.dashboards.intakeDashboardButton.innerHtml}
        </Button>
      )
    }
    if (gridRows.dashboards.hasAccessCaseManager) {
      gridRows.dashboards.hasAccess = true
      gridRows.dashboards.buttons.push(
        <Button
          {...buttonProps(gridRows.dashboards.caseManagerDashboardButton)}
        >
          {gridRows.dashboards.caseManagerDashboardButton.innerHtml}
        </Button>
      )
    }
    if (gridRows.dashboards.hasAccessSupervisor) {
      gridRows.dashboards.hasAccess = true
      gridRows.dashboards.buttons.push(
        <Button
          {...buttonProps(gridRows.dashboards.supervisorDashboardButton)}
        >
          {gridRows.dashboards.supervisorDashboardButton.innerHtml}
        </Button>
      )
    }
    // // dashboards2 TO SEE WHAT 4 BUTTON ROW WILL LOOK LIKE FOR DEBUGGING  see gridRows.dashboards2
    // if (gridRows.dashboards2.hasAccessIntake) {
    //   gridRows.dashboards2.hasAccess = true
    //   gridRows.dashboards2.buttons.push(
    //     <Button
    //       {...buttonProps(gridRows.dashboards2.intakeDashboardButton)}
    //     >
    //       {gridRows.dashboards2.intakeDashboardButton.innerHtml}
    //     </Button>
    //   )
    // }
    // if (gridRows.dashboards2.hasAccessCaseManager) {
    //   gridRows.dashboards2.hasAccess = true
    //   gridRows.dashboards2.buttons.push(
    //     <Button
    //       {...buttonProps(gridRows.dashboards.caseManagerDashboardButton)}
    //     >
    //       {gridRows.dashboards2.caseManagerDashboardButton.innerHtml}
    //     </Button>
    //   )
    // }
    // if (gridRows.dashboards2.hasAccessCaseManager2) {
    //   gridRows.dashboards2.hasAccess = true
    //   gridRows.dashboards2.buttons.push(
    //     <Button
    //       {...buttonProps(gridRows.dashboards2.caseManagerDashboardButton2)}
    //     >
    //       {gridRows.dashboards2.caseManagerDashboardButton2.innerHtml}
    //     </Button>
    //   )
    // }
    // if (gridRows.dashboards2.hasAccessSupervisor) {
    //   gridRows.dashboards2.hasAccess = true
    //   gridRows.dashboards2.buttons.push(
    //     <Button
    //       {...buttonProps(gridRows.dashboards2.supervisorDashboardButton)}
    //     >
    //       {gridRows.dashboards2.supervisorDashboardButton.innerHtml}
    //     </Button>
    //   )
    // }
    // Tools And Settings
    if (gridRows.toolsAndSettings.hasAccessCampaigns) {
      gridRows.toolsAndSettings.hasAccess = true
      gridRows.toolsAndSettings.buttons.push(
        <Button
          {...buttonProps(gridRows.toolsAndSettings.campaignsButton)}
        >
          {gridRows.toolsAndSettings.campaignsButton.innerHtml}
        </Button>
      )
    }
    if (gridRows.toolsAndSettings.hasAccessSettings) {
      gridRows.toolsAndSettings.hasAccess = true
      gridRows.toolsAndSettings.buttons.push(
        <Button
          {...buttonProps(gridRows.toolsAndSettings.settingsButton)}
        >
          {gridRows.toolsAndSettings.settingsButton.innerHtml}
        </Button>
      )
    }

    /*
         At render time, this function will assign the correct css class depending on where the button fell in the row.
         Left / Middle / Right
      */
    const getClassnamePlacement = (length, current) => {
      if (length) {
        if (length > 1) { // More than one in a row
          if (length > 2) { // Going to have a left / middle(s) / right
            if (length > 3) return styles.buttonDivMiddle // All middle for as much space (auto margin) as possible
            if (current === 0) return styles.buttonDivMiddleLeft
            if (current === length - 1) return styles.buttonDivMiddleRight
            return styles.buttonDivMiddle
          } else {
            // Is exactly 2 so
            // Left and Right
            if (current) return styles.buttonDivRight
            return styles.buttonDivLeft
            // OR
            // Both middle
            // return styles.buttonDivMiddle
          }
        } else {
          return styles.buttonDivMiddle // Only one in the row
        }
      }
    }

    return (
      <App user={user} location={false} accessRules={accessRules} headerBoard={HeaderBoardComponent} snackProvider>
        {user?.permissions && user.permissions.includes('read:dashboard') &&
        <div page={thisFile.slice(thisFile.lastIndexOf('/') + 1).trim()} >
          <div style={{ textAlign: 'center' }}>
            <Typography variant='h4' style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
              CaseOpp Quick Links
            </Typography>
          </div>
          <Grid
            container
            {...gridProps}
          >
            {Object.entries(gridRows).filter(([rowKey, rowValue]) => rowValue.hasAccess).map(([rowKey, rowValue]) => {
              return (
                <Grid
                  key={rowKey}
                  container
                  {...gridProps}
                >
                  <Grid item xs={12} >
                    <Typography variant='h4' style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', paddingBottom: '1em' }}>
                      {rowValue.title}
                    </Typography>
                  </Grid>
                  {rowValue.buttons.map((button, key) => (
                    <Grid key={key} item xs >
                      <div className={getClassnamePlacement(rowValue.buttons.length, key)}>
                        {button}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              )
              // }
            })}
          </Grid>
          {/* <div style={{ textAlign: 'center' }} id='signedCasesChartDiv' name='signedCasesChartDiv' /> */}
        </div>}
      </App>
    )
  } else {
    return (
      <App user={user} location={false} accessRules={accessRules}>
        <Welcome loading={user === undefined} from='index' />
      </App>
    )
  }
}

export default Index
